<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <!--<div class="row">
          <div class="col-md-12">-->
            <div class="brick"></div>

            <div class="number">
              <div class="four"></div>
              <div class="zero">
                  <div class="nail"></div>
              </div>
              <div class="four"></div>
            </div>


          <!--</div>
        </div>-->
      </div>
      <div class="col-md-12 text-end">
        <div class="info">
          <h2>Algo salió mal</h2>
          <p>La página que está buscando se movió, quitó, renombró o podría no existir.</p>
          <button type="button" class="btn btn-lg btn-rounder bnt-light"
          (click)="navegation('/')">Inicio</button>
        </div>
      </div>


    </div>
  </div>


</section>


<footer id="footer">
  <div class="container">
      <!-- Worker -->
      <div class="worker"></div>
      <!-- Tools -->
      <div class="tools"></div>
  </div>
  <!-- end container -->
</footer>
