import { Component, Input, OnInit } from '@angular/core';
import { CardProfile } from '../../../../interface/interfaces';


@Component({
  selector: 'app-profile1',
  templateUrl: './profile1.component.html',
  styleUrls: ['./profile1.component.css']
})
export class Profile1Component implements OnInit {
  @Input() team!: CardProfile;

  constructor() { }

  ngOnInit(): void {
  }

}
