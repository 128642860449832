<div *ngIf="existeProducto === true; else error404">
  <app-menu></app-menu>

  <app-header-producto [producto]="producto"></app-header-producto>
  <app-llamanos></app-llamanos>

  <section id="descProd"  [className]="producto.characters ? 'bg-white' : 'bg-gris'">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 text-center">
          <h2>{{producto.title}}</h2>
          <hr class="azul">
          <p class="descPro">{{producto.description}}</p>
        </div>
      </div>
    </div>
  </section>


  <section id="characters" *ngIf="producto.characters">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 text-center pb-5">
          <h2><b>{{ "Descripción Del Producto" | uppercase }}</b></h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-12 col-lg-12 cuadro-desc p-lg-5 mb-5 p-3" *ngFor="let item of producto.characters; let i = index; let par = even">
          <div class="row" *ngIf="par; else impar">
            <div class="col-md-6 text-center p-3">
              <h2 class="titulo-desc pb-3">{{item.title}}</h2>
              <p class="descPro" *ngIf="item.textDescription">{{item.textDescription}}</p>
              <ul class="list-group list-group-flush">
                <ul class="list-group text-start">
                  <li class="list-group-item" *ngFor="let list of item.listDescription" style="padding-bottom: 0; padding-top: 0;">
                    <div class="d-flex flex-row bd-highlight">
                      <div class="p-2 bd-highlight">
                        <i class="fas fa-check-circle list-icon"></i>
                      </div>
                      <div class="p-2 bd-highlight">
                        <span class="text-span">{{list}}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </ul>
            </div>
            <div class="col-md-6 text-center my-auto mx-auto text-center">
              <img [src]="item.img" [alt]="item.title" class="img-fluid img-prod-desc">
            </div>
          </div>

          <ng-template #impar>
            <div class="row">
              <div class="col-md-6 text-center my-auto mx-auto">
                <img [src]="item.img" [alt]="item.title" class="img-fluid img-prod-desc">
              </div>
              <div class="col-md-6 text-center p-3">
                <h2 class="titulo-desc pb-3">{{item.title}}</h2>
                <p class="descPro" *ngIf="item.textDescription">{{item.textDescription}}</p>
                <ul class="list-group list-group-flush">
                  <ul class="list-group text-start">
                    <li class="list-group-item" *ngFor="let list of item.listDescription" style="padding-bottom: 0; padding-top: 0;">
                      <div class="d-flex flex-row bd-highlight">
                        <div class="p-2 bd-highlight">
                          <i class="fas fa-check-circle list-icon"></i>
                        </div>
                        <div class="p-2 bd-highlight">
                          <span class="text-span">{{list}}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </ul>

              </div>
            </div>
          </ng-template>

        </div>
      </div>
    </div>
  </section>

  <section id="otherProds">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 text-center mb-5">
          <h2><b>Productos Que Te Pueden Interesar</b></h2>
        </div>

        <div class="col-md-4 text-center item p-3" *ngFor="let prod of productos | ramdomArray : 3 : producto.title">
          <app-producto [producto] = "prod"></app-producto>
        </div>

        <div class="col-md-4 col-lg-4 p-5">
          <button type="button" class="btn btn-lg btn-rounder bnt-azul"
          (click)="navegation('/productos')">Ver Productos</button>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>
</div>

<ng-template #error404>
  <app-error404></app-error404>
</ng-template>
