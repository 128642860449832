<section id="comentarios">
  <div class="container text-center">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h2 class="section-heading"> ¿QUÉ DICEN NUESTROS CLIENTES DE SODINFO?</h2>
        <hr class="light my-4">
      </div>
    </div>

  </div>
  <div id="sliderComentarios" class="carousel slide mt-5" data-bs-ride="carousel">
    <div class="carousel-inner text-center">
      <div class="carousel-item" *ngFor="let comment of comentarios; let i = index" [ngClass]="{'active': i === 0}">
        <div class="row">
          <div class="col-lg-8 mx-auto text-center">
            <div class="d-flex justify-content-center">
              <div class="p-1 bd-highlight">
                <i class="fas fa-quote-left comillas-icon-comment"></i>
              </div>
              <div class="p-1 bd-highlight">
                <h4 class="text-comment">{{comment.comentary | upperFirstLetter}}</h4>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <div class="p-1 bd-highlight">
                <hr class="hr-comment">

              </div>
              <div class="p-1 bd-highlight">
                <span class="text-name-comment text-span">{{comment.name | titlecase}}</span>
              </div>
              <div class="p-1 bd-highlight">
                <hr class="hr-comment">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
