import { Component, Input} from '@angular/core';
import { Productos } from 'src/app/interface/interfaces';

@Component({
  selector: 'app-header-producto',
  templateUrl: './header-producto.component.html',
  styleUrls: ['./header-producto.component.css']
})
export class HeaderProductoComponent{
  @Input() producto!: Productos;


}
