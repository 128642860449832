import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProductsComponent } from './pages/products/products.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { QuienesSomosComponent } from './pages/quienes-somos/quienes-somos.component';
import { LoginComponent } from './pages/login/login.component';
import { ValidarTokenGuard } from './guards/validar-token.guard';
import { FacturacionElectronicaComponent } from './pages/facturacion-electronica/facturacion-electronica.component';
import { HelpFacturacionElectronicaComponent } from './pages/help-facturacion-electronica/help-facturacion-electronica.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'quienes-somos',
    component: QuienesSomosComponent
  },
  {
    path: 'productos',
    component: ProductsComponent
  },
  {
    path: 'producto',
    loadChildren: () => import('./pages/products/productos/productos.module').then(module => module.ProductosModule)
  },
  {
    path: 'contactos',
    component: ContactsComponent,
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'facturacion-electronica',
    component: FacturacionElectronicaComponent
  },
  {
    path: 'ayuda-facturacion-electronica',
    component: HelpFacturacionElectronicaComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(module => module.AdminModule),
    canActivate: [ValidarTokenGuard],
    canLoad: [ValidarTokenGuard]
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
