<app-menu></app-menu>
<!--inicio slider-->
<!--<section id="slider">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
              aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="10000">
              <img src="../assets/slider/prueba_slider.jpg" class="d-block w-100" alt="...">
              <div class="carousel-caption d-none d-md-block text-white">
                <h2 class="titulo-azul">CONTAPLUS</h2>
                <p>Some representative placeholder content for the second slide.</p>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src="../assets/slider/example-slide-2.jpg" class="d-block w-100" alt="...">
              <div class="carousel-caption d-none d-md-block text-white">
                <h2 class="titulo-azul">CONTAPLUS</h2>
                <p>Some representative placeholder content for the second slide.</p>
              </div>
            </div>
            <div class="carousel-item">
              <img src="../assets/slider/example-slide-1.jpg" class="d-block w-100" alt="...">
              <div class="carousel-caption d-none d-md-block text-white">
                <h2 class="titulo-azul">CONTAPLUS</h2>
                <p>Some representative placeholder content for the second slide.</p>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev " type="button" data-bs-target="#carouselExampleDark"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next " type="button" data-bs-target="#carouselExampleDark"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>-
        </div>
      </div>
    </div>
  </div>
</section>-->

<!--<header>
  <div id="home" class="nt-section after-header">
    <div class="container-fluid pl-0 pr-0">
      <div class="row no-gutters ">
        <div class="nt-column col-sm-12">
          <div id="start-screen" class="start-screen start-screen--style-4">
            <div class="start-screen__bg-container">
              <div class="start-screen__bg" style="background-image: url(https://ninetheme.com/themes/techland/wp-content/uploads/2019/05/bg_1.jpg);background-position: center bottom;"></div></div><div class="start-screen__shapes  d-none d-lg-block"><img src="https://ninetheme.com/themes/techland/wp-content/uploads/2019/05/shape_1-3.png" class="img-shape" alt="shape_1-3.png"></div><div class="start-screen__content-container"><div class="start-screen__content__item align-items-center text-center"><div class="container"><div class="row justify-content-sm-center"><div class="col-12 col-md-11 text-white"><h1>The Fastest &amp; Simplest VPN <span>Gain Secure Internet.</span></h1><p>Vimba, zebrafish yellow and black triplefin guitarfish Redfin perch<br>
    tripod fish zebra lionfish, nase slickhead! Jewelfish</p><div class="spacer py-4"></div><a class="custom-btn custom-btn--big custom-btn--style-5" href="#services">Discover</a></div></div></div></div></div></div></div></div></div></div>
</header>-->
<header>
  <div id="slide">
    <section>
      <div class="container" style="padding: 110px 0 205px 0;">
        <div class="row justify-content-center " >
          <div class="col-md-12 col-lg-8 text-center mt-5">
            <h1>SODINFO CIA LTDA</h1>
          </div>
          <div class="col-md-12 col-lg-8 text-center">
            <span class="text-span">#masQueUnaEmpresa</span>
          </div>
          <div class="col-md-12 col-lg-8 text-center pt-5">
            <button type="button" class="btn btn-lg btn-rounder bnt-azul m-2"
            (click)="navegation('/quienes-somos')">Conocenos!</button>

            <button type="button" class="btn btn-lg btn-rounder btn-outline-info bnt-celeste-outline m-2"
            (click)="navegation('/contactos')">Contáctanos</button>
          </div>
        </div>
        <!-- <div class="row justify-content-center">
          <div class="col-md-3 col-lg-2 text-center">
            <div class="mainIcon d-flex flex-column mb-5">
              <i class="icon fas fa-laptop-code d-block mb-3"></i>
              <span>Software</span>
            </div>

          </div>
          <div class="col-md-3 col-lg-2 text-center">
            <div class="mainIcon d-flex flex-column mb-5">
              <i class="icon fas fa-headset d-block mb-3"></i>
            <span>Soporte Técnico</span>
            </div>

          </div>
          <div class="col-md-3 col-lg-2 text-center">
            <div class="mainIcon d-flex flex-column mb-5">
              <i class="icon fas fa-file-invoice-dollar d-block mb-3"></i>
              <span>Facturación Electrónica</span>
            </div>

          </div>
          <div class="col-md-3 col-lg-2 text-center">
            <div class="mainIcon d-flex flex-column mb-5">
              <i class="icon fas fa-gas-pump d-block mb-3"></i>
              <span>Gasolineras</span>
            </div>

          </div>
        </div> -->
      </div>
    </section>
  </div>
</header>

<!--Fin slider-->


<!--Breve desc empresa-->

<section class="bg-primary" id="nosotros">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h2 class="section-heading text-white"> SODINFO CLTDA</h2>
        <span class="text-celeste text-span">#tuEmpresaAmiga!</span>
        <hr class="light my-4">
        <p class="text-faded mb-4">SODINFO CIA.LTDA. Se dedica al diseño, desarrollo y aplicación de Hardware y Software personalizado con la más actual Tecnología puesta al servicio y a la medida de las diferentes líneas de negocios. </p>
        <button type="button" class="btn btn-lg btn-rounder bnt-celeste-outline"
          (click)="navegation('/productos')">Conocenos!</button>
      </div>
    </div>
  </div>


</section>
<!--Fin breve desc-->

<!--Inicio Servicios-->

<section id="servicios">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center mb-5">
        <h2 class="section-heading">Nuestros Productos</h2>
        <span class="text-celeste text-span">#automatizandoProcesos</span>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4 text-center item p-3" *ngFor="let producto of servicios | ramdomArray : 3">
        <app-producto [producto] = "producto"></app-producto>
      </div>

      <div class="col-md-12 col-lg-12 p-5 text-center">
        <button type="button" class="btn btn-lg btn-rounder bnt-azul"
        (click)="navegation('/productos')">Ver Productos</button>
      </div>
    </div>
  </div>
</section>

<!--Fin Servicios-->




<!--mettricas-->
<section id="metricas" (window:scroll)="onScroll($event)"class="bg-primary">
  <div class="container">
    <div class="row text-center">
      <div class="col-lg-8 mx-auto text-center mb-5">
        <h2 class="section-heading text-white">SODINFO MÉTRICAS</h2>
        <span class="text-celeste text-span">#juntosSomosMas</span>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3 text-center mb-3">
            <i class="fas fa-users"></i>
            <h2 class="mb-2 display-3 metrica">{{visitas}}</h2>
            <h4><b>Clientes</b></h4>
          </div>

          <div class="col-md-3 text-center mb-3">
            <i class="fas fa-user-astronaut"></i>
            <h2 class="mb-2 display-3 metrica">{{proyectos}}</h2>
            <h4><b>Proyectos</b></h4>
          </div>

          <div class="col-md-3 text-center mb-3">
            <i class="fas fa-user-graduate"></i>
            <h2 class="mb-2 display-3 metrica">{{capacitaciones}}</h2>
            <h4><b>Capacitaciones</b></h4>
          </div>

          <div class="col-md-3 text-center mb-3">
            <!--<i class="fas fa-users-cog mb-4"></i>-->
            <i class="fas fa-user-tie"></i>
            <h2 class="mb-2 display-3 metrica">{{colaboraciones}}</h2>
            <h4><b>Colaboraciones</b></h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!--fin de metricas-->


<!-- comentarios-->
  <app-slider-comentarios></app-slider-comentarios>
<!--fin comentarios-->


<!--clientes aliados-->
  <app-clientes-aliados></app-clientes-aliados>
  <!--fin clientes aliados-->

<!--FOOTER-->
<app-footer></app-footer>
<!--fin footer-->
