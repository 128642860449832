import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Productos } from '../../../interface/interfaces';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-productos-descripcion',
  templateUrl: './productos-descripcion.component.html',
  styleUrls: ['./productos-descripcion.component.css']
})
export class ProductosDescripcionComponent implements OnInit {
  @Input() producto!: Productos;
  existeProducto: boolean = false;
  productos: Productos[] = [];

  constructor(private router: ActivatedRoute, private dataService: DataService){
    this.router.url.subscribe( resp =>  {
      const url = (resp[0].path);
      const path = `producto/${url}`;
      const prod = this.dataService.getProducto(path);
      console.log(prod);
      if(prod){
        this.producto = prod;
        this.existeProducto = true;
      }
    });
   }

  ngOnInit(): void {
    this.productos = this.dataService.getProductos();
  }

  navegation(url:string):void{
    this.dataService.navegation(url);
  }

}
