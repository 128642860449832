import { Component } from '@angular/core';
import { CardProfile } from 'src/app/interface/interfaces';
const url = '';

@Component({
  selector: 'app-quienes-somos',
  templateUrl: './quienes-somos.component.html',
  styleUrls: ['./quienes-somos.component.css']
})
export class QuienesSomosComponent {

  firstTeams: CardProfile[] = [
      {
        image: "../../../assets/team/Juan_Mendez.jpeg",
        firstName: "Juan Méndez Cuesta",
        lastName: "Presidente"
      },
      {
        image: "../../../assets/team/Fredy_Mendez.jpeg",
        firstName: "Fredy Méndez Cuesta",
        lastName: "Gerente"
      },
      {
        image: "../../../assets/team/Paul_Mendez.jpeg",
        firstName: "Paul Méndez Cuesta",
        lastName: "Jefe Dpto. Soporte y Asesoría"
      }
  ];

   secondTeams: CardProfile[] = [
    {
      image: "../../../assets/team/Diego_Mendez.jpg",
      firstName: "Diego Méndez",
      lastName: "Soporte Técnico en Facturación Electrónica"
    },
    {
      image: "../../../assets/team/Flavio_Roman.jpg",
      firstName: "Flavio Román",
      lastName: "Desarrollador Web Full Stack",
      social: [
        {
          icon: 'fab fa-linkedin-in',
          name: 'linkedin',
          url: 'https://www.linkedin.com/in/flavio-roman-a38aa48a/'
        }
      ]
    },
    {
      image: "../../../assets/team/Norma_Gerrero.jpg",
      firstName: "Norma Guerrero",
      lastName: "Directora de Marketing",

    },
    {
      image: "../../../assets/team/Andres_Alava.jpeg",
      firstName: "Ing. Andrés Álava",
      lastName: "SOPORTE y ASESORÍA"
    },
    {
      image: "../../../assets/team/Cristopher_Mendez_Guerrero.jpeg",
      firstName: "Ab. Cristopher Méndez Guerrero",
      lastName: "SOPORTE y ASESORÍA"
    },
    {
      image: "../../../assets/team/Betzy_Cuenca_de_Mendez.jpeg",
      firstName: "Ab. Betzy Cuenca de Méndez",
      lastName: "SOPORTE y ASESORÍA"
    }
  ];

  thirtyTeams: CardProfile[] = [
    {
      image: "../../../assets/team/Maria_Placencia.jpg",
      firstName: "Maria Placencia",
      lastName: "Contadora",

    },
    {
      image: "../../../assets/team/Mary.jpg",
      firstName: "María del Carmen Matute",
      lastName: "Auxiliar en Contabilidad",

    },
    {
      image: "../../../assets/team/Alejandro_Mendez_Olivares.jpeg",
      firstName: "Sr. Alejandro Méndez Olivares",
      lastName: "Auxiliar Contable"
    },
    {
      image: "../../../assets/team/Martha_Olivares.jpeg",
      firstName: "Sra. Martha Olivares A",
      lastName: "Secretaria",

    }
  ];
    /*,
    {
      image: "http://envato.jayasankarkr.in/code/profile/assets/img/profile-2.jpg",
      firstName: "Juan José Flores",
      lastName: "Presidente",
      social: [
        {
          icon: 'fab fa-facebook',
          name: 'facebook',
          url: 'https://www.facebook.com/'
        },
        {
          icon: 'fab fa-twitter',
          name: 'twitter',
          url: 'https://www.twitter.com/'
        },
        {
          icon: 'fab fa-instagram',
          name: 'instagram',
          url: 'https://www.instagram.com/'
        }
      ]
    },
    {
      image: "http://envato.jayasankarkr.in/code/profile/assets/img/profile-2.jpg",
      firstName: "Lola Perez",
      lastName: "Secretaria",

    },
    {
      image: "http://envato.jayasankarkr.in/code/profile/assets/img/profile-2.jpg",
      firstName: "Mayra Arcos",
      lastName: "Publicista",

    }
  ];*/


}
