<section id="clientesAliados">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center pb-5">
        <h2 class="section-heading">BANDERA DE NUESTROS CLIENTES</h2>
        <span class="text-celeste text-span">#clientesSastisfechos</span>
      </div>
      <div class="col-md-2 col-lg-2 text-center m-3" *ngFor="let cliente of clientes">
        <a [href]="cliente.url" *ngIf="cliente.url != ''; else elseCliente" target="_blank">
          <img [src]="cliente.logo" class="img-fluid animate__animated animate__fadeIn" alt="cliente.name">
        </a>

        <ng-template #elseCliente>
          <img [src]="cliente.logo" class="img-fluid  animate__animated animate__fadeIn" alt="cliente.name">
        </ng-template>

      </div>
    </div>
    <!--<div class="row justify-content-center">
      <div class="col-md-8 text-center p-5">
        <button type="button" class="btn btn-lg btn-rounder bnt-azul"
        (click)="loadClientes(clientes.length)">Cargar Más</button>
      </div>
    </div>-->
  </div>
</section>
