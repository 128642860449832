import { Component, OnInit } from '@angular/core';

interface Contacto{
  url: string;
  name: string;
  title: string;
  icon: string;
}

@Component({
  selector: 'app-llamanos',
  templateUrl: './llamanos.component.html',
  styleUrls: ['./llamanos.component.css']
})
export class LlamanosComponent implements OnInit {

  contactos: Contacto [] = [
    {
      url: 'mailto:sodinfocialtda@gmail.com',
      name: 'sodinfocialtda@gmail.com',
      title: '¿Inquietudes?',
      icon: 'fas fa-envelope'
    },
    {
      url: 'tel:+593991605502',
      name: '+593 99 160 5502',
      title: 'Contáctanos',
      icon: 'fas fa-phone'
    },
    {
      url: 'https://wa.me/+593994071795?text=Hola%SODINFO',
      name: '+593 99 407 1795',
      title: 'WhatsApp',
      icon: 'fab fa-whatsapp'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
