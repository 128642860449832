import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Productos } from 'src/app/interface/interfaces';
import { DataService } from '../services/data.service';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @HostListener('scroll', ['$event']) public onScroll($event: any): void {
    const el = document.getElementById('metricas');
    let coords = el!.getBoundingClientRect();
    this.posicionMetrica = (coords.top + window.scrollY) - 300;

    if (Number(window.scrollY) >= Number(this.posicionMetrica)) {
      //console.log("POSICION ENCONTRADA*******************", this.posicionMetrica);
      setInterval(() => {
        this.randomMetricas();
      }, 50)

    }
  }
  servicios: Productos[] = [];
  /*servicios: Productos[] = [
    {
      icon: 'fas fa-gas-pump',
      title: 'Control de Gestión de Gasolinera',
      text: 'Sistema de administracón que permite controlar todos los procesos de compra / venta de combustible y su administración financiera',
      url: 'control-de-Gestion-de-gasolinera'
    },
    {
      icon: 'fas fa-bong',
      title: 'Lectura Computarizada de Tanques',
      text: 'Sistema de administracón que permite controlar todos los procesos de compra / venta de combustible y su administración financiera',
      url: 'lectura-computarizada-de-tanques'
    },
    {
      icon: 'fas fa-store',
      title: 'Automatización y Control en Market',
      text: 'Sistema de administracón que permite controlar todos los procesos de compra / venta de combustible y su administración financiera',
      url: 'automatizacion-y-control-en-market'
    },
    {
      icon: 'fas fa-donate',
      title: 'Sistema integrado de contabilidad',
      text: 'Sistema de administracón que permite controlar todos los procesos de compra / venta de combustible y su administración financiera',
      url: 'sistema-integrado-de-contabilidad'
    },
    {
      icon: 'fas fa-balance-scale-right',
      title: 'Lectua automática de báscula',
      text: 'Sistema de administracón que permite controlar todos los procesos de compra / venta de combustible y su administración financiera',
      url: 'lectua-automática-de-bascula'
    },
    {
      icon: 'fas fa-file-invoice',
      title: 'Facturación electrónica',
      text: 'Sistema de administracón que permite controlar todos los procesos de compra / venta de combustible y su administración financiera',
      url: 'facturacion-electronica'
    },
  ];*/

  visitas: number = 0;
  proyectos: number = 0;
  capacitaciones: number = 0;
  colaboraciones: number = 0;
  posicionMetrica: number = 0;
  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.servicios = this.dataService.getProductos();

  }

  navegation(ruta: string) {
    this.dataService.navegation(ruta);
  }


  randomMetricas() {
    if (this.visitas < 184) {
      this.visitas++;
    }

    if (this.proyectos < 83) {
      this.proyectos++;
    }

    if (this.capacitaciones < 158) {
      this.capacitaciones++;
    }

    if (this.colaboraciones < 35) {
      this.colaboraciones++;
    }

  }

}
