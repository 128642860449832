import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'src/app/interface/interfaces';
import { DataService } from '../../pages/services/data.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit{

  menuItems: MenuItem[] = [];

  menuRightItems: MenuItem[] = [];

  //menuAplicaciones: MenuItem[] = [];

  constructor(private dataService: DataService){

  }
  ngOnInit(): void {
    this.menuItems = this.dataService.menuItems;
    this.menuRightItems = this.dataService.menuRightItems;
    //this.menuAplicaciones = this.dataService.menuAplicaciones;
  }



}
