import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

interface Header{
  title: string;
  desc: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent{

  /*@Input() title: string = '';
  @Input() description: string = '';*/

  title: string = '';
  constructor(private router: ActivatedRoute){
    this.router.url.subscribe( resp =>  {
      let url = (resp[0].path);
      let path = url.replace('-', ' ');
      path = (path === "quienes somos")? this.title = "¿ Quiénes Somos ?" : this.title = path;
    });
  }



}
