import { Component, OnInit } from '@angular/core';
import { Productos } from 'src/app/interface/interfaces';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  productos: Productos[] = [];
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.productos = this.dataService.getProductos();
  }
}
