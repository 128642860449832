<footer class="page-footer font-small unique-color-dark">

  <div class="redesSociales">
    <div class="container">

      <!-- Grid row-->
      <div class="row py-4 d-flex align-items-center">

        <!-- Grid column -->
        <div class="col-md-6 col-lg-5 text-center text-md-left mb-md-0">
          <h6 class="mb-0">Contáctanos a nuestras redes sociales!</h6>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 col-lg-7 text-center text-md-right">

          <!-- Facebook -->
          <a class="fb-ic" href="https://www.facebook.com/sodinfo.cialtda" target="_blank">
            <i class="fab fa-facebook-f white-text m-2"> </i>
          </a>
          <!-- Twitter -->
          <!-- <a class="tw-ic" href="https://www.facebook.com/#/" target="_blank">
            <i class="fab fa-twitter white-text m-2"> </i>
          </a> -->
          <!-- Google +-->
          <!-- <a class="gplus-ic" href="https://www.facebook.com/#/" target="_blank">
            <i class="fab fa-google-plus-g white-text m-2"> </i>
          </a> -->
          <!--Linkedin -->
          <!-- <a class="li-ic" href="https://www.facebook.com/#/" target="_blank">
            <i class="fab fa-linkedin-in white-text m-2"> </i>
          </a> -->
          <!--Instagram-->
          <a class="ins-ic" href="https://www.instagram.com/sodinfocialtda/" target="_blank">
            <i class="fab fa-instagram white-text"> </i>
          </a>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row-->

    </div>
  </div>

  <!-- Footer Links -->
  <div class="container text-center text-md-left mt-5" id="footerMedio">

    <!-- Grid row -->
    <div class="row mt-3">

      <!-- Grid column -->
      <div class="col-md-6 col-lg-4 col-xl-3 mx-auto mb-4">

        <!-- Content -->
        <h6 class="text-uppercase font-weight-bold">SODINFO CIA LTDA</h6>
        <hr class="deep-yellow accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>SODINFO CIA.LTDA. Se dedica al diseño, desarrollo y aplicación de Hardware y Software personalizado con la más actual Tecnología puesta al servicio y a la medida de las diferentes líneas de negocios.</p>

      </div>
      <!-- Grid column -->


      <!-- Grid column -->
      <div class="col-md-6 col-lg-2 col-xl-2 mx-auto mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Páginas</h6>
        <hr class="deep-yellow accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p *ngFor="let item of menuItems" style="margin: 0px;">
          <a class="nav-link active" aria-current="page" [routerLink]="[ item.ruta ]"
            routerLinkActive="active">{{item.nombre}}</a>

        </p>


      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-6 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Contactos</h6>
        <hr class="deep-yellow accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">


        <h6 class="text-uppercase font-weight-bold"><i class="fas fa-home mr-3"></i> Cuenca, Azuay, EC</h6>
        <p>
          <i class="fas fa-phone mr-3"></i> <a href="tel:+593991605502"> +593 991 605 502</a>
        </p>

        <h6 class="text-uppercase font-weight-bold"><i class="fas fa-home mr-3"></i> Machala, El Oro, EC</h6>
        <p>
          <i class="fas fa-phone mr-3"></i> <a href="tel:+593994071795"> +593 994 071 795</a>
        </p>

      </div>
      <!-- Grid column -->

       <!-- Grid column -->
       <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Contactos</h6>
        <hr class="deep-yellow accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">



        <p>
          <i class="fas fa-envelope mr-3"></i> <a href="mailto:sodinfocialtda@gmail.com"> sodinfocialtda@gmail.com</a>
        </p>
        <p>
        <p>
          <i class="fas fa-globe"></i> <a href="https://www.sodinfo.com.ec/" target="_blank"> www.sodinfo.com.ec</a>
        </p>



      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3" >© {{anio}}, hecho con  <i class="fas fa-heart corazon-footer animate__animated animate__heartBeat animate__infinite infinite"></i>  por
    <a router-link="/" routerLinkActive="active" (click)="navegation('/')"> <b>SODINFO CIA LTDA</b> </a> para ti.

  </div>
  <!-- Copyright -->

</footer>
