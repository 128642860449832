import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upperFirstLetter'
})
export class UpperFirstLetterPipe implements PipeTransform {

  transform(texto: string): string {
    texto = texto[0].toUpperCase() + texto.slice(1);
    return texto;
  }

}
