<nav class="navbar navbar-expand-lg navbar-light menu fixed-top">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/']">
      <img src="../../../assets/logo_sodinfo_cltda.png" alt="SODINFO CLTDA" width="70" height="70">
      SODINFO
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon text-span"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto me-auto  mb-2 mb-lg-0"> <!--ms-auto-->
        <li class="nav-item" *ngFor="let item of menuItems; let i = index; let ultimo = last"  [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link"  aria-current="page" [routerLink]="[ item.ruta ]" *ngIf="!ultimo"><i [class]="item.icon"></i> {{item.nombre}}</a>
          <a  aria-current="page" [routerLink]="[ item.ruta ]" *ngIf="ultimo">
            <button type="button" class="btn btn-outline-info"> {{item.nombre}}</button>
          </a>
        </li>

       <!--  <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Sodinfo App's
          </a>
          <ul class="dropdown-menu">
            <li *ngFor="let item of menuAplicaciones; let i = index">
              <a class="dropdown-item" *ngIf="item.target; else elseItemApp" [href]="item.ruta" [target]="item.target" ><i [class]="item.icon"></i> {{item.nombre}}</a>
              <ng-template #elseItemApp>
                <a class="dropdown-item" [routerLink]="[ item.ruta ]"><i [class]="item.icon"></i> {{item.nombre}}</a>
              </ng-template>
            </li>
          </ul>
        </li> -->

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Cuenta
          </a>
          <ul class="dropdown-menu">
            <li *ngFor="let item of menuRightItems; let i = index">
              <a *ngIf="(item.web === 'inside'); else outside" class="dropdown-item"   [routerLink]="[ item.ruta ]" >
                <i [class]="item.icon"></i> {{item.nombre}}
              </a>

              <ng-template #outside>
                <a class="dropdown-item" [href]="item.ruta" [target]="item.target">
                  <i [class]="item.icon"></i> {{item.nombre}}
                </a>
              </ng-template>
            </li>
          </ul>
        </li>
      </ul>

    </div>
  </div>
</nav>


<!--<nav class="navbar navbar-default navbar-fixed-top">
  <div class="navbar-header">
    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#resNav">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <a href="#" class="navbar-brand">Company Logo</a>
  </div>
  <div class="collapse navbar-collapse" id="resNav">
    <ul class="nav navbar-nav navbar-right">
      <li><a href="#">Home</a></li>
      <li><a href="#">About</a></li>
      <li><a href="#">Services</a></li>
      <li><a href="#">Team</a></li>
      <li><a href="#">Contact</a></li>
    </ul>
  </div>
</nav>-->
