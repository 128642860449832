import { Component } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.css']
})
export class Error404Component {

  constructor(private DataService: DataService) { }

  navegation(path: string): void{
    this.DataService.navegation(path);
  }
}
