import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Comentario } from '../../../interface/interfaces';
import { CommonModule } from '@angular/common';

interface ComentariosSlider{
  comment: string;
  name: string;
}

@Component({
  selector: 'app-slider-comentarios',
  templateUrl: './slider-comentarios.component.html',
  styleUrls: ['./slider-comentarios.component.css']
})
export class SliderComentariosComponent implements OnInit{

  comentarios: Comentario[] = [
    {
      comentary: 'Sodinfo es la mejor empresa de software del ecuador',
      name: 'Paula Perez'
    },
    {
      comentary: 'Prestos a ayudarnos en todo momento',
      name: 'José Merchan'
    },
    {
      comentary: 'Sodinfo nos ayuda con las capacitaciones de todo nuestro personal',
      name: 'Pedro Velez'
    },
    {
      comentary: 'Ahora podemos administrar de mejor manera nuestra empresa garcias al software de Sodinfo',
      name: 'Sonia Erreyes'
    }
  ];

  constructor(private dataService: DataService){

  }
  ngOnInit(){
   this.dataService.getComments()
    .subscribe( resp => {
      console.log(resp);
      if(resp.status === true){
        if(resp.data.length > 3){
          this.comentarios = resp.data;
        }else{
          resp.data.forEach(comentario => {
            this.comentarios.push(comentario);
          });

        }
      }
    })
  }



}
