
  <div class="card profile-card-3">
    <img [src]="team.imageBackground" *ngIf="team.imageBackground; else imagenDefault"
      alt="profile-sample1" class="background" />
      <ng-template #imagenDefault>
        <img src="https://images.pexels.com/photos/946351/pexels-photo-946351.jpeg?w=500&h=650&auto=compress&cs=tinysrgb"
        alt="profile-sample1" class="background" />

      </ng-template>

    <img [src]="team.image" [alt]="team.firstName" class="profile img img-fluid" />
    <div class="card-content">
      <h2>{{team.firstName}} <small>{{team.lastName}}</small></h2>
        <div class="icon-block">
          <a [href]="social.url" *ngFor="let social of team.social">
            <i [class]="social.icon"></i>
          </a>
        </div>
    </div>
  </div>

