import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Comentarios, MenuItem, Productos, RespuestaLogin } from 'src/app/interface/interfaces';
import { environment } from '../../../environments/environment';
import { Comentario, RespuestaComentario, Login, ResponseLoginFacturacion, LoginFacturacionElectronica, RecuperarClaveFacturacionElectronica, ResponseRecuperarClave } from '../../interface/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const URL = environment.URL;
const URL_SERVER = environment.URL_SERVER;

@Injectable({
  providedIn: 'root'
})



export class DataService {


  menuItems: MenuItem[] = [
    {
      ruta: '/',
      nombre: 'Inicio',
      icon: 'fas fa-home',
      align: 'left'
    },
    {
      ruta: '/quienes-somos',
      nombre: 'Quienes Somos',
      icon:'fas fa-user-tie',
      align: 'left'
    },
    {
      ruta: '/productos',
      nombre: 'Productos',
      icon: 'fas fa-briefcase',
      align: 'left'
    },
    {
      ruta: '/contactos',
      nombre: 'Contactos',
      icon: 'fas fa-id-card-alt',
      align: 'left'
    },{
      ruta: '/facturacion-electronica',
      nombre: 'Facturación Electrónica',
      icon: 'fas fa-id-card-alt',
      align: 'left'
    }
  ];

  menuRightItems: MenuItem[] = [
    {
      ruta: `${URL_SERVER}/sodinfocard/`,
      nombre: 'Sodinfo Card',
      icon: 'fas fa-id-card',
      align: 'left',
      target: '_blank',
      web: 'outside'
    },
    {
      ruta: '/login',
      nombre: 'Login',
      icon: 'far fa-user',
      align: 'right',
      web: 'inside'
    }
  ];

  /*menuAplicaciones: MenuItem[] = [


  ]*/

  productos: Productos[] = [
    {
      icon: 'fas fa-gas-pump',
      nuevo: false,
      title: 'Control de Gestión de Gasolinera',
      url: 'producto/control-de-gestion-de-gasolinera',
      img: '../assets/productos/control_gasolinera/control_gasolinera_header.png',
      description: 'Sistema de administración que permite controlar todos los procesos de compra / venta de combustible y su administración financiera',
      characters: [
        {
          title: 'CONTROL DE FLOTAS',
          typeDescription: 'list',
          listDescription: [
            'Registro de información requerida (chofer, código de chofer, placa del vehículo, kilometraje y resumen)',
            'Envío automático de los créditos otorgados a la Web para que el cliente pueda hacer su consulta y al correo o correos registrados.'
          ],
          img: '../assets/productos/flotas/flotas_header.png'
        },
        {
          title: 'CUANTÍAS POS',
          typeDescription: 'list',
          listDescription: [
            'Consulta del saldo disponible en cuantías por cliente en islas.',
            'Emisión del documento y autorización del transporte de venta de combustibles por cuantías.'
          ],
          img: '../../../assets/productos/control_gasolinera/cuantias.png'
        },
        {
          title: 'SENSORES',
          typeDescription: 'text',
          textDescription: 'SDF Tank, es un desarrollo que aprovecha la mejor tecnología en Hardware y Software, con el cual nos permite minuto a minuto obtener el inventario físico real y exacto del combustible por tanque, además de complementar con niveles de agua y temperatura. Esta información es guardada en una base de datos para realizar luego reportes estadísticos e informativos del comportamiento y flujo de los niveles.',
          img: '../../../assets/productos/contaplus/sodinfo_ContaPLus_header.png'
        },
        {
          title: 'BENEFICIOS',
          typeDescription: 'list',
          listDescription: [
            'Facilidad de control en Stock.',
            'Conocimiento en tiempo real para la nueva adquisición de combustible',
            'Control exacto de las cargas y descargas.',
            'Reportes estadísticos de volumen total o parcial en: (COMPRAS - VENTAS - EVAPORIZACIONES)'
          ],
          img: '../../../assets/productos/contaplus/contaplus2.png'
        },
        {
          title: 'TanKIDE',
          typeDescription: 'text',
          textDescription: 'TanKIDE es la información (pantalla) para los usuarios. Aquí puede observar los niveles totales de agua y temperatura, además del galonaje correspondiente al nivel físico del combustible. Control de descarga del tranquero de los combustibles para determinar exactamente y de acuerdo a la tabla volumétrica del tanque cuanto combustible se descargó del tanquero. Control del inventario historial de los tanques para determinar estadísticas diarias, semanales o mensuales. Serie 1.- Inventario físico (Azul) Serie 2.- Saldo según sistemas (Rojo) Serie 3.- Diferencia (Verde)',
          img: '../../../assets/productos/tanques/tikcet.png'
        },
        {
          title: 'OTROS REPORTES',
          typeDescription: 'text',
          textDescription: 'Además contamos con las versión WEB, con la cual usted puede supervisar todo el proceso desde cualquier parte del mundo, y desde cualquier móvil inteligente con acceso a internet.',
          img: '../assets/productos/control_gasolinera/control_gasolinera_reportes.png'
        }
      ]
    },
    {
      icon: 'fas fa-bong',
      nuevo: false,
      title: 'Lectura Computarizada de Tanques',
      url: 'producto/lectura-computarizada-de-tanques',
      img: '../assets/productos/tanques/tanques_header.png',
      description: 'SDF Tank, es un desarrollo que aprovecha la mejor tecnología  en hardware y software, con el cual nos permite minuto a minuto obtener el inventario físico real y exacto del combustible por tanque, además de complementar con niveles de AGUA y temperatura. Esta información es guardada en una base de datos para realizar luego reportes estadísticos e informativos del comportamiento y flujo de los niveles. ',
      characters: [
        {
          title: 'BENEFICIOS',
          typeDescription: 'list',
          listDescription: [
            'Facilidad de control en Stock.',
            'Conocimiento en tiempo real para la nueva adquisición de combustible.',
            'Control exacto de las cargas y descargas.',
            'Reportes estadísticos de volumen total o parcial en: (Compras - Ventas - Evaporizaciones)'
          ],
          img: '../assets/productos/tanques/tanques_header.png'
        },
        {
          title: 'TanklDE ',
          typeDescription: 'text',
          textDescription: 'TanklDE es la información (pantalla) para los usuarios. Aquí puede observar los niveles totales de agua y temperatura, además del galonaje correspondiente al nivel físico del combustible. Control de descarga del tanquero de los combustibles para determinar exactamente y de acuerdo a la tabla volumétrica del tanque cuanto combustible se descargó del tanquero. Control del inventario historial de los tanques para determinar estadísticas diarias, semanales o mensuales.',
          img: '../assets/productos/tanques/tikcet.png'
        },
        {
          title: 'Reporte en Base a 1 día en la Estación de Servicio en el Tanque de Diésel. Día 4 de Julio. Desde las 00h hasta las 23:59:59h.',
          typeDescription: 'list',
          listDescription: [
            'Serie 1.- Inventario Físico (Azul).',
            'Serie 2.- Saldo Según Sistema (Rojo)',
            'Serie 3.-  Diferencia (Verde)'
          ],
          img: '../assets/productos/tanques/tanques_reportes.png'
        },
        {
          title: 'Version Web',
          typeDescription: 'text',
          textDescription: 'Además Contamos con la Versión WEB, con la cual usted puede supervisar todo el proceso desde cualquier parte del mundo, y desde cualquier móvil inteligente con acceso a internet.',
          img:'../assets/productos/contaplus_web/contaplus_web5.png'
        }
      ]
    },
    {
      icon: 'fas fa-store',
      nuevo: false,
      title: 'Automatización y Control en Market',
      url: 'producto/automatizacion-y-control-en-market',
      img: '../../../assets/productos/market/market_header.png',
      description: 'Mediante este modulo, la empresa podrá controlar cada uno de los procesos de compra y venta de productos. Además de obtener reportes en tiempo real de todos los movimientos que se realicen en el market.',
      characters: [
        {
          title: 'Administración del Market',
          typeDescription: 'list',
          listDescription: [
            'Módulo de compras',
            'Módulo de ventas',
            'Inventarios',
            'Reporte',
            'Reporte de ítems más vendidos',
            'Reportes de ítems menos vendidos',
            'Generación automática de pedidos en base a los movimientos del ítem'
          ],
          img: '../../../assets/productos/market/market1.png'
        }
      ]
    },
    {
      icon: 'fas fa-balance-scale-right',
      nuevo: false,
      title: 'Lectura automática de báscula',
      url: 'producto/lectura-automatica-de-bascula',
      img: 'https://image.freepik.com/vector-gratis/ilustracion-vector-concepto-abstracto-ley-matrimonial-derecho-familia-propiedad-matrimonial-custodia-hijos-decreto-divorcio-balanza-justicia-documento-firma-mazo-juez-metafora-abstracta-acuerdo_335657-1943.jpg',
      description: 'Por medio de este módulo interconectamos Contaplus con una báscula para registrar compras, ventas con pesos automatizados y de lectura directa. Además, el sistema posee paramatrizaciones especiales de acuerdo al tipo de negocios o al tipo de producto que se esté comprando o vendiendo como son: humedad, pureza y otros factores por los cuales sea necesario hacer un cálculo de porcentajes o valores finales en pagos o cobros.',

    },
    {
      icon: 'fas fa-file-invoice',
      nuevo: false,
      title: 'Facturación electrónica',
      text: 'El sistema de Facturación Electrónica es una herramienta de software diseñada para la automatización integral en el proceso de Autorización de sus documentos electrónicos en conexo al Sistema del Servicio de Rentas Internas . Ha sido desarrollado y construido usando las más modernas normas, para facilitar así su operación sin la necesidad de grandes esfuerzos en capacitación. Entre sus características generales se destacan las siguientes: Control de Acceso, Facilidad de Uso, Cumplimiento Regulatorio',
      url: 'producto/facturacion-electronica',
      img: '../assets/productos/facturacion/facturacion_electronica_header.png',
      description: 'Sistema de administracón que permite controlar todos los procesos de compra / venta de combustible y su administración financiera',
      characters: [
        {
          title: 'Características',
          typeDescription: 'list',
          listDescription: [
            'Mantenimiento de Usuario.',
            'Mantenimiento de Clientes',
            'Envío de Correo',
            'Reporte por Documento',
            'Reporte por Totales',
            'Consulta de Documentos'
          ],
          img: '../assets/productos/facturacion/facturacion_electronica.png'
        }
      ]
    },
    {
      icon: 'fas fa-truck',
      nuevo: false,
      title: 'SODINFO FLOTAS',
      url: 'producto/sodinfo-flotas',
      img: '../../../assets/productos/flotas/flotas_header.png',
      description: 'Con el fin de optimizar, agilizar, y supeditar de mejor manera hemos desarrollado un módulo para CONTROL DE FLOTAS.',
      characters: [
        {
          title: 'El mismo que le ofrece:',
          typeDescription: 'list',
          listDescription: [
            'Obtener un control de flotas de vehículos categorizando por perfil (empresas, compañías, cooperativas, etc.)',
            'Restricciones y con facilidad de uso.',
            'Reportes y consultas personalizadas online mediante la web de los consumos realizados y los saldos.',
          ],
          img: '../../../assets/productos/flotas/flotas1.png'
        },{
          title: 'Registro detallado de cada despacho con los siguientes criterios:',
          typeDescription: 'list',
          listDescription: [
            'CLIENTE; (ruc, nombre, dirección)',
            'VEHÍCULO; (placa, kilometraje, detalle)',
            'CHOFER; (nombre, código)',
            'DESPACHADOR; (código, nombre)',
            'Fácil e intuitivo tanto para el vendedor (despachador, isleros); como para la persona o personas (administrador, clientes, choferes, inspectores, etc.) que tuvieran a cargo el manejo de este módulo.'
          ],
          img: '../../../assets/productos/control_gasolinera/control_gasolinera_reportes.png'
        }
      ]

    },
    {
      icon: 'fab fa-innosoft',
      nuevo: false,
      title: 'CONTAPLUS',
      url: 'producto/contaplus',
      img: '../assets/productos/contaplus/sodinfo_ContaPLus_header.png',
      description: 'ContaPlus es una herramienta que integra cada uno de los departamentos de su empresa. Llevando el control del manejo de toda su operatividad. Así también manejo múltiples empresas, usuarios, teniendo un control de acceso por usuarios de acuerdo a los permisos concedidos. Cumple con las necesidades de todo tipo de empresa y/o Estación de Servicios, se encuentra bajo los estándares del ambiente Windows, utilizando para este fin Visual Basic como herramientas de desarrollo, Cristal Reports como herramienta para sus reportes y FireBird como base de datos, que le permite manejar grandes cantidades de transacciones de una forma segura, confiable e integrada.',
      characters: [
        {
          title: 'Características',
          typeDescription: 'list',
          listDescription: [
            'Contabilidad',
            'Inventarios',
            'Facturación',
            'Cuentas por cobrar',
            'Cuentas por pagar',
            'Caja – bancos',
            'Rol de pagos',
            'Tributación',
            'Documentos Electrónicos',
            'Control de tarjetas de créditos'
          ],
          img: '../assets/productos/contaplus/contaplus1.png'
        },{
          title: 'Características',
          typeDescription: 'list',
          listDescription: [
            'Implementación rápida',
            'Parametrizable y muy flexible',
            'Diseño modular e integrado',
            'Interface amigable y estándar',
            'Cumple estándares tributarios',
            'Optimiza procesos Administrativos y financieros al aumentar la productividad y eficiencia',
            'Registra información en el lugar en que se generó para su utilización inmediata en otras áreas',
            'Facilita la oportuna toma de decisiones',
            'La respuesta a sus aspiraciones sobre un sistema Administrativo – Financiero',
            'Plan de Cuentas parametrizable 100%',
            'MultiActividad'
          ],
          img: '../assets/productos/contaplus/contaplus2.png'
        }
      ]

    },
    {
      icon: 'fas fa-globe-americas',
      nuevo: true,
      title: 'SODINFO WEB',
      url: 'producto/sodinfo-web',
      img: '../../../assets/productos/contaplus_web/contaplus_web_header.png',
      description: 'Si disfrutas de CONTAPLUS, entonces no te puedes perder de su versión web, que le permitirá administrar sus empresas desde cualquier parte del mundo y estar al tanto de cualquier movimiento en todo momento.',
      characters: [
        {
          title: 'DISEÑO ADAPTABLE',
          typeDescription: 'text',
          textDescription: 'CONTAPLUS WEB se adapta a tu dispositivo móvil. Lo que le permite disfrutar de esta aplicación desde cualquier dispositivo móvil como: smartphone, Tablet o laptop.  La versión de CONTAPLUS WEB está diseñada para que cada uno de sus componentes y procesos puedan adaptarse a las medidas de pantalla de su dispositivo.',
          img: '../../../assets/productos/contaplus_web/contaplus_web1.png'
        },{
          title: 'REPORTES',
          typeDescription: 'text',
          textDescription: 'Visualizar los reportes en la web de una forma dinámica, atractiva e intuitiva. Así mismo estos reportes podrán ser enviados a su correo en formato PDF o EXCEL. Todos los reportes pueden ser filtrados según sea los datos que necesite obtener en la consulta de dichos reportes.',
          img: '../../../assets/productos/contaplus_web/contaplus_web2.png'
        },{
          title: 'PANEL ADMINISTRATIVO DE COMPRAS',
          typeDescription: 'list',
          listDescription: [
            'Mantenimiento de proveedores.',
            'Factura de compra',
            'Nota de crédito',
            'Anticipos',
            'Pagos',
            'Reportes'
          ],
          img: '../../../assets/productos/contaplus_web/contaplus_web3.png'
        },{
          title: 'PANEL ADMINISTRATIVO DE VENTAS',
          typeDescription: 'list',
          listDescription: [
            'Mantenimiento de clientes',
            'Factura de venta',
            'Nota de crédito',
            'Cobros',
            'Egreso de bodega',
            'Anticipos',
            'Retenciones',
            'Consolidación de ventas',
            'Reportes'
          ],
          img: '../../../assets/productos/contaplus_web/contaplus_web4.png'
        },{
          title: 'PANEL ADMINISTRATIVO DE CONTABILIDAD',
          typeDescription: 'list',
          listDescription: [
            'Mantenimiento de clientes',
            'Ingresos',
            'Egresos',
            'Diarios',
            'Reportes'
          ],
          img: '../../../assets/productos/contaplus_web/contaplus_web5.png'
        },{
          title: 'PANEL ADMINISTRATIVO DE BANCOS',
          typeDescription: 'list',
          listDescription: [
            'Mantenimiento de banco',
            'Módulo de movimientos',
            'Consolidación',
            'Reportes'
          ],
          img: '../../../assets/productos/contaplus_web/contaplus_web2.png'
        },{
          title: 'OTROS MÓDULOS ADMINISTRATIVOS',
          typeDescription: 'list',
          listDescription: [
            'Recursos humanos',
            'Gasolinera',
            'Otros reportes'
          ],
          img: '../../../assets/productos/contaplus_web/contaplus_web6.png'
        },

      ]

    },{
      icon: 'fas fa-credit-card',
      nuevo: true,
      title: 'SODINFO CARD',
      url: 'producto/sodinfo-card',
      img: '../../../assets/productos/sodinfo_card/sodinfo_card_header.png',
      description: 'SODINFO CARD es una app web, diseñada para que los usuarios consumir combustible, realizar retiros en efectivo y compras en el market de las gasolineras, mediante una tarjeta. Las tarjetas a solicitar pueden ser (PREPAGOS – POSTPAGO). A conveniencia del cliente, las cuales puedan recargarlas en cualquier momento y poder utilizar todos los servicios que preste una gasolinera utilizando nada más que su tarjeta de consumo.',
      characters: [
        {
          title: 'SODINFO CARD SE ADAPTA A TU DISPOSITIVO',
          typeDescription: 'text',
          textDescription: 'isfruta de SODINFO CARD desde la comodidad de tu teléfono móvil, laptop o Tablet. Tan solo asegúrate de que tu dispositivo tenga conexión a internet y podrás disfrutar de nuestra app y poder consultar saldos, obtener reportes de movimientos y administrar tu perfil.',
          img: '../../../assets/productos/sodinfo_card/sodinfo_card_tarjeta2.png'
        },{
          title: 'ROLES DE USUARIOS',
          typeDescription: 'text',
          textDescription: 'Cada usuario tendrá un rol y de esta manera las comercializadoras, empresas, clientes, choferes, vehículos, market y despachadores. Podrán realizar operaciones según su rol se lo permita. De esta manera SODINFO CARD brinda a cada usuario la seguridad de que tantos sus datos como las actividades que realice con su tarjeta serán administradas de forma confiable.',
          img: '../../../assets/productos/sodinfo_card/sodinfo_card_choferes.png'
        },{
          title: 'TARJETAS',
          typeDescription: 'text',
          textDescription: 'Las tarjetas serán únicas y exclusivas para cada usuario. El usuario en conjunto con la gasolinera escogerá cuál es el tipo de tarjeta que más le conviene (PREPAGO – POSTPAGO). Además, estas tarjetas están divididas en TARJETAS CORRIENTES y Tarjetas UNIVERSALES. Las tarjetas corrientes, permitirán consumos de: combustible, market, retiros en efectivo. Mientras que las tarjetas universales pueden realizar este mismo tipo de acciones, pero además pueden tener asociadas cuantas tarjetas corrientes desee el usuario, de esta manera si una tarjeta corriente se queda sin saldo, puede empezar a consumir el saldo de la tarjeta universal.',
          img: '../../../assets/productos/sodinfo_card/sodinfo_card_tarjeta1.png'
        },{
          title: 'RECARGAS',
          typeDescription: 'text',
          textDescription: 'Puedes recargar en cualquier momento tu tarjeta, además que puedes recargarla para realizar consumos en general como: (consumos en el market o retiros en efectivo). Pero también puedes recargarla para un producto en específico como: (SUPER – EXTRA – DIESEL).',
          img: '../../../assets/productos/sodinfo_card/sodinfo_card_recargas1.png'
        },{
          title: 'VEHÍCULOS',
          typeDescription: 'text',
          textDescription: 'Si tienes una empresa que cuenta con una flota de vehículos, en el rol de cliente puedes registrar todos los vehículos y a sus choferes. Además de asignar una tarjeta ya sea para el vehículo o para el chofer y opcionalmente especificar en la tarjeta que producto podrá ser recargado y consumido con la tarjeta.',
          img: '../../../assets/productos/sodinfo_card/sodinfo_card_vehiculos.png'
        },{
          title: 'RETIROS EN EFECTIVO',
          typeDescription: 'text',
          textDescription: 'Si deseas utilizar este servicio, la empresa que otorgue la tarjeta, deberá habilitar esta función en su tarjeta. Pero además al consumir este tipo de servicio, SODINFO CARD le notificará al titular de la tarjeta que se está realizando esta operación, el monto, el lugar y hora exacta del procedimiento. Adjuntándole un código de verificación que será quien brinde seguridad para que solo mediante ese código se puede consumir este tipo de servicio. ',
          img: '../../../assets/productos/sodinfo_card/sodinfo_card_recargas2.png'
        },{
          title: 'REPORTES',
          typeDescription: 'text',
          textDescription: 'SODINFO CARD le permite al usuario poder realizar cualquier consulta y además estos reportes pueden ser enviados al correo electrónico del usuario. Existen reportes para las empresas, reportes de movimientos, reportes de consumos, reportes de estados de cuenta. Los cuales podrás acceder en todo momento. ',
          img: '../../../assets/productos/sodinfo_card/sodinfo_card_reportes1.png'
        }

      ]
    }

  ];

  /*headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json' });*/

    headers = new HttpHeaders({
      'Content-Type':'application/json; charset=utf-8',
      'Access-Control-Allow-Credentials' : 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    });

  constructor(
    private router: Router,
    private http: HttpClient
    ) { }

  navegation(ruta: string) {
    this.router.navigate([`${ruta}`]);
  }

  getProductos(){
    return this.productos;
  }

  getProducto(path: string){
    console.log("path",path);
    const producto = this.productos.find(p => p.url === path);

    return producto;
  }

  getComments(){
    const options = { headers: this.headers };
    return this.http.get<Comentarios>(`${URL}/allcomentarios`, options);
  }


  storeComment(data: Comentario) {

    const options = { headers: this.headers };

    return this.http.post<RespuestaComentario>(`${URL}/comentarios`, data, options);
  }

  login(data: Login){
    const options = {headers: this.headers};
    return this.http.post<RespuestaLogin>(`${URL}/login`, data, options).pipe(
      map( resp => {
        const usuario = [
          {
            "id": resp.user!.id,
            "email": resp.user!.email,
            "photo": resp.user!.photo,
            "token": resp.token,
            "person": {
              "id": resp.user!.person.id,
              "first_name": resp.user!.person.first_name,
              "last_name": resp.user!.person.last_name,
              "slug": resp.user!.person.slug,
              "ruc": resp.user?.person.ruc,
              "phone": resp.user?.person.phone,
              "address": resp.user?.person.address,
              "birthday": resp.user?.person.birthday
            },
            "roles": resp.user!.roles
          }
        ];

        localStorage.setItem('apiSodinfo', JSON.stringify(usuario));
        return resp;
      })
    );
  }

  loginFacturacionElectronica(data: LoginFacturacionElectronica){
    const options = {headers: this.headers};
    return this.http.post<ResponseLoginFacturacion>(`${URL_SERVER}/programas/loginFacturacion.php`, data, options);
  }

  recuperarClaveFacturacionElectronica(data: RecuperarClaveFacturacionElectronica){
    const options = {headers: this.headers};
    return this.http.post<ResponseRecuperarClave>(`${URL_SERVER}/programas/recuperarClave.php`, data, options);
  }
}
