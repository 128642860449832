<section class="bg-primary" style="padding: 3rem 0 !important;" id="llamanos">
  <div class="container">
    <!--<div class="row justify-content-center">
      <div class="col-md-4" *ngFor="let item of contactos">-->
        <div class="d-flex justify-content-center flex-wrap " >
          <div *ngFor="let item of contactos" class="m-md-1 ms-lg-5 me-lg-5">
            <div class="d-flex justify-content-center" >
              <div class="p-2 bd-highlight">
                <i  [class] ="item.icon" class="icon-contactos animate__animated animate__bounce "></i>
              </div>
              <div class="p-2 bd-highlight">
                <h5 class="titulo-contacto"><b>{{item.title}}</b></h5>
                <h5><a [href]="item.url" target="_blank">{{item.name}}</a></h5>
              </div>
            </div>

          </div>

        </div>
      <!--</div>
    </div>-->
  </div>
</section>
