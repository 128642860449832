<div class="tile-wrapper tile-iphone-12-pro large-12 pre-order-2 order-swap theme-dark">

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 text-center pb-5">
        <h1 class="titulo-azul"><b>{{producto.title | uppercase}}</b></h1>
        <hr class="azul">
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-8 text-center">
        <img [src]="producto.img" [alt]="producto.title" class="img-fluid">
      </div>
    </div>
  </div>

</div>
