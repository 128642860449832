import { Component, OnInit } from '@angular/core';

interface ClientesAliados{
  logo: string;
  url?: string;
  name: string;
}

@Component({
  selector: 'app-clientes-aliados',
  templateUrl: './clientes-aliados.component.html',
  styleUrls: ['./clientes-aliados.component.css']
})
export class ClientesAliadosComponent{

  //contador: number = 0;
  clientes: ClientesAliados[] = [
    {
      logo: '../assets/clientes/energygas.png',
      name: 'ENERGYGAS',
      url: 'https://energygas.com.ec/'
    },
    {
      logo: '../assets/clientes/masgas.png',
      name: 'MASGAS',
      url: 'https://www.masgas.com.ec/'
    },
    {
      logo: '../assets/clientes/p&s.png',
      name: 'P&S',
      url: 'https://www.petroleosyservicios.com/web/'
    },
    {
      logo: '../assets/clientes/pdv.png',
      name: 'PDV',
      url: 'https://www.lubricantespdv.com.ec/'
    },
    {
      logo: '../assets/clientes/petroecuador.png',
      name: 'PETROECUADOR',
      url: ''
    },
    {
      logo: '../assets/clientes/petrolrios.png',
      name: 'PETROLRIOS',
      url: ''
    },
    {
      logo: '../assets/clientes/primax.png',
      name: 'PRIMAX',
      url: 'https://www.primax.com.ec/'
    },
    {
      logo: '../assets/clientes/terpel.png',
      name: 'TERPEL',
      url: ''
    }
  ];

  /*clientes: ClientesAliados[] = [];


  constructor() {
      this.loadClientes(this.contador);
  }


  loadClientes(index: number = 0): void{
    this.contador = this.contador + 12;
    if(this.contador > this.totalClientes.length){
      return;
    }
    for(let i = index; i < this.contador; i++){
      this.clientes.push(this.totalClientes[i]);
    }
  }*/

}
