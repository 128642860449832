<div class="profile-card-3 text-center">
  <div class="hijo">
    <img [src]="team.image" class="img img-responsive" width="350" height="500">

    <div class="profile-name">
      {{team.firstName | uppercase}}
    </div>
    <div class="profile-username">
      {{team.lastName | titlecase}}
    </div>
    <div class="profile-icons">
      <a [href]="social.url" *ngFor="let social of team.social">
        <i [class]="social.icon"></i>
      </a>
    </div>
  </div>

</div>
