import { Component, Input} from '@angular/core';
import { Productos } from 'src/app/interface/interfaces';
import { DataService } from 'src/app/pages/services/data.service';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css']
})
export class ProductoComponent {
  @Input() producto!: Productos;

  constructor(private dataService: DataService){

  }

  navigation(url: string):void{
    this.dataService.navegation(url);
  }

}
