import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import Swal from 'sweetalert2';
import { LoginFacturacionElectronica, RecuperarClaveFacturacionElectronica } from '../../interface/interfaces';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-facturacion-electronica',
  templateUrl: './facturacion-electronica.component.html',
  styleUrls: ['./facturacion-electronica.component.css']
})
export class FacturacionElectronicaComponent implements OnInit {
  URL_SERVER = environment.URL_SERVER;
  loading: boolean = false;
  messageLoader: string = "Cargando";

  loginFormFacturacion: FormGroup = this.fb.group({
    usuario:  ['', [Validators.required]],
    password: ['', Validators.required],
    perfil: [3, [Validators.required]]
  });

  recupearClaveForm: FormGroup = this.fb.group({
    usuario:  ['', [Validators.required]],
  });

  get usuarioErrorMsg(): string{
    const erros = this.loginFormFacturacion.get('usuario')?.errors;
    if(erros?.required){
      return 'El usuario es obligatorio';
    }
    return '';
  }

  get usuarioPasswordErrorMsg(): string{
    const erros = this.recupearClaveForm.get('usuario')?.errors;
    if(erros?.required){
      return 'El usuario es obligatorio';
    }
    return '';
  }

  get passwordErrorMsg(): string{
    const erros = this.loginFormFacturacion.get('password')?.errors;
    if(erros?.required){
      return 'La contrseña es obligatoria';
    }
    return '';
  }

  get perfilErrorMsg(): string{
    const erros = this.loginFormFacturacion.get('perfil')?.errors;
    if(erros?.required){
      return 'El perfil es obligatorio';
    }
    return '';
  }
  constructor(private dataService: DataService, private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  validarCampo(campo: string){
    return this.loginFormFacturacion.controls[campo]?.errors && this.loginFormFacturacion.controls[campo]?.touched;
  }

  async loginFacturacion(){
    this.loading = true;
    if(this.loginFormFacturacion.invalid){
      this.loginFormFacturacion.markAllAsTouched();
      this.loading = false;
      return;
    }

    const data: LoginFacturacionElectronica = this.loginFormFacturacion.value;

    await this.dataService.loginFacturacionElectronica(data).subscribe(resp => {
      console.log(resp);
      this.loading = false;
      if(!resp.status){
        console.log('es faslo');
        this.alertaSimple('Upsss!' , resp.message!, 'error');
        return;
      }

      window.open(`${this.URL_SERVER}/${resp.redirect}`, '_self');

      /*if( window.location.host === 'www.sodinfo.com.ec' ){
        console.log("es igual");
        //window.open(`${resp.redirect}`, '_self');
      }else{
        console.log("no es igual");
        //window.open(`${this.URL_SERVER}/${resp.redirect}`, '_self');
      }*/
      this.loginFormFacturacion.reset();
      //this.dataService.navegation(resp.redirect!);
     //this.goToPage(resp.redirect!);
    }, (err) =>{
      console.log(err);
      this.loading = false;
      this.alertaSimple('Upsss!' , err.message!, 'error');
    });
  }

  alertaSimple(titulo: string, texto:string, icono:any = 'success'){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: icono,
      timer: 5000,
      timerProgressBar: true,
      showCloseButton: true,
      confirmButtonColor: '#43baff',
    })
  }

  goToPage(url: string) {
    window.location.href = url;
  }

  async restaurarClave(){
    this.loading = true;
    if(this.recupearClaveForm.invalid){
      this.recupearClaveForm.markAllAsTouched();
      this.loading = false;
      return;
    }

    const data: RecuperarClaveFacturacionElectronica = this.recupearClaveForm.value;

    await this.dataService.recuperarClaveFacturacionElectronica(data).subscribe( resp => {
      console.log(resp);
      this.loading = false;
      if(!resp.status){
        this.alertaSimple('Upsss!' , resp.message!, 'error');
        return;
      }
      this.recupearClaveForm.reset();
      this.alertaSimple('Bien Hecho!', resp.message, 'success');
    }, (err) =>{
      this.loading = false;
      this.alertaSimple('Upsss!' , err.message!, 'error');
    });
  }
}
