<!--<nav class="navbar navbar-expand-lg navbar-light fixed-top">
  <div class="container">
    <a class="navbar-brand" href="#">Mouri</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <a class="nav-link" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Portfolio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Contact</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="https://i.postimg.cc/bNQp0RDW/1.jpg" alt="First slide">
      <div class="carousel-caption d-none d-md-block">
        <h5>Slider One Item</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, nulla, tempore. Deserunt excepturi quas vero.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="https://i.postimg.cc/pVzg3LWn/2.jpg" alt="Second slide">
      <div class="carousel-caption d-none d-md-block">
        <h5>Slider One Item</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, nulla, tempore. Deserunt excepturi quas vero.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="https://i.postimg.cc/0y2F6Gpp/3.jpg" alt="Third slide">
      <div class="carousel-caption d-none d-md-block">
        <h5>Slider One Item</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, nulla, tempore. Deserunt excepturi quas vero.</p>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
-->
<div *ngIf="loading === false; else load">
  <app-menu></app-menu>

  <app-header></app-header>

  <app-llamanos></app-llamanos>


  <section id="formContacto" class="bg-gray">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 text-center">
          <span class="text-celeste text-span">#ayudanosASerMejores</span>
          <h2 class="section-heading">Contáctanos</h2>
          <hr class="azul my-4">

          <h5>Si tienes alguna pregunta, por favor, no dude en enviarnos un mensaje. Gustosamente le respondemos dentro de las próximas 24 horas !</h5>
      </div>
      </div>
      <div class="row justify-content-center">

        <div class="col-md-12 col-lg-5 p-5 text-center">
          <div class="d-flex justify-content-center p-3">
            <div class="p-2 bd-highlight">
              <i  class="fas fa-clock animate__animated animate__bounce "></i>
            </div>
            <div class="p-2 bd-highlight">
              <h5 class="titulo-contacto"><b>Horario de Atención</b></h5>
              <h5>24 / 7</h5>
            </div>
          </div>

          <div class="d-flex justify-content-center p-3">
            <div class="p-2 bd-highlight">
              <i  class="fas fa-phone animate__animated animate__bounce "></i>
            </div>
            <div class="p-2 bd-highlight">
              <h5 class="titulo-contacto"><b>Ventas</b></h5>
              <h5 class="linkTel"><a href="tel:+593991605502">+593 99 160 5502</a></h5>
            </div>
          </div>
          <br>

          <div class="d-flex justify-content-center p-3">
            <div class="p-2 bd-highlight">
              <i  class="fas fa-phone animate__animated animate__bounce "></i>
            </div>
            <div class="p-2 bd-highlight">
              <h5 class="titulo-contacto "><b>Soporte Técnico</b></h5>
              <h5 class="linkTel"><a href="tel:+593994071795">+593 99 407 1795</a></h5>

            </div>
          </div>
          <br>



        </div>
        <div class="col-md-12 col-lg-5 p-5 text-center">
          <h4 class="text-start"><b>Dejanos Tú Comentario</b></h4>
          <p class="text-start">Tu comentario, nos sirve para seguir mejorando nuestros productos y servicios. Además todos los podrémos visualizar en nuestra pagina principal</p>
          <form class="text-start" autocomplete="off" [formGroup]="contactoFormulario" (ngSubmit)="guardar">
            <div class="mb-3">
              <!--<label for="nombre" class="form-label">Nombres</label>-->
              <input type="text" formControlName="name" class="form-control" id="nombre" placeholder="Nombre" required>
              <span class="form-text text-danger" *ngIf="validarCampo('name')">{{nameErrorMsg}}</span>
            </div>
            <div class="mb-3">
              <!--<label for="telefono" class="form-label">Teléfono</label>-->
              <input type="phone" formControlName="phone" class="form-control" id="telefono" placeholder="Teléfono" required>
              <span class="form-text text-danger" *ngIf="validarCampo('phone')">{{phoneErrorMsg}}</span>
            </div>
            <div class="mb-3">
              <!--<label for="correo" class="form-label">Correo Electrónico</label>-->
              <input type="email" class="form-control" formControlName="email" id="correo" placeholder="Correo Electrónico" required>
              <span class="form-text text-danger" *ngIf="validarCampo('email')">{{emailErrorMsg}}</span>
            </div>
            <div class="mb-3">
              <!--<label for="comentario" class="form-label">Comentario</label>-->
              <textarea class="form-control" formControlName="comentary" id="comentario" rows="3" required placeholder="Comentario"></textarea>
              <span class="form-text text-danger" *ngIf="validarCampo('comentary')">{{comentaryErrorMsg}}</span>
            </div>

            <button type="submit" class="btn btn-primary mb-3" [disabled]="contactoFormulario.invalid" (click)="guardar()">Comentar</button>
          </form>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>
</div>


<ng-template #load>
  <app-loader [mensaje]="messageLoader"></app-loader>
</ng-template>
