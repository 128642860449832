<app-menu></app-menu>

<app-header></app-header>

<!--Breve desc empresa-->

<section class="bg-primary" id="nosotros">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h2 class="section-heading text-white"> SODINFO CIA LTDA </h2>
        <hr class="light my-4">
        <p class="text-faded mb-4">SODINFO, Es una empresa familiar que nació de la fusión con JMC System, quien tuvo su comienzo en el año 2008, siendo su eje la Automatización de Gasolineras a nivel provincial(El Oro). <br><br> Gracias a las recomendaciones de nuestra selecta clientela y a las nuevas disposiciones de los entes reguladores de control, se dio la necesidad de crecer y expandirse<br><br>Es así que en año 2014 se dio la fusión y el nacimiento de Soluciones Digitales Informáticas  (SODINFO) la misma que está conformada con personal con vasta experiencia en análisis y control de procesos complementando con la creatividad de personal joven, quienes están encargados del diseño y desarrollo, de acuerdo a necesidades o requerimientos del cliente.</p>
      </div>
    </div>
  </div>


</section>
<!--Fin breve desc-->


<!--mision-->

<section id="mision-vision">
  <div class="container">
    <div class="row">
      <div class="col-md-6 pt-5 pb-5">
        <h2 class="titulo-azul"><b>Misión</b></h2>

        <p>Ofrecer soluciones y servicios de Desarrollo de Sistemas Integrados, tanto en Hadware  como en Software, aplicados a un área específica como son: Estaciones de Servicio, Básculas, etc.</p>
        <p>Nuestro Cometido es que el funcionamiento de los Equipos quede en Óptimas Condiciones,  mejorando el rendimiento y facilitando el uso para el personal a cargo del manejo</p>
      </div>
      <div class="col-md-6 pb-5 text-center">
        <img src="./assets/quienes-somos/mision.png" alt=""
          class="img-fluid">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6  pb-5 text-center">
        <img src="./assets/quienes-somos/vision.png" alt=""
          class="img-fluid">
      </div>
      <div class="col-md-6 pt-5 pb-5">
        <h2 class="titulo-azul"><b>Visión</b></h2>

        <p>SODINFO Cia.Ltda, busca ser  Líder en Soluciones Digitales, brindando diferentes Servicios para optimizar el funcionamiento de sus equipos y así satisfacer las necesidades de nuestros clientes, proporcionando Atención Personalizada.<br><br>
          Nuestra Empresa está en constante Innovación a la par de los avances Tecnológicos  para proporcionar una atención ideal a quienes necesitan y confían en esta su Compañía </p>
      </div>
    </div>
  </div>
</section>
<!--fin mision-->

<!--reseña-->
<!-- <section id="resena" class="bg-gris">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h2 class="section-heading"> NUESTRA HISTORIA</h2>
        <hr class="azul my-4">
        <p class="mb-4">SODINFO, Es una empresa familiar que nació de la fusión con JMC System, quien tuvo su comienzo en el año 2008, siendo su eje la Automatización de Gasolineras a nivel provincial(El Oro). <br><br> Gracias a la recomendaciones de nuestra selecta clientela y a las nuevas disposiciones de los entes reguladores de control, se dio la necesidad de crecer y expandirse<br><br>Es así que en año 2014 se dio la fusión y el nacimiento de Soluciones Digitales Informáticas  (SODINFO) la misma que está conformada con personal con vasta experiencia en análisis y control de procesos complementando con la creatividad de personal joven, quienes están encargados del diseño y desarrollo, de acuerdo a necesidades o requerimientos del cliente.</p>

      </div>
    </div>
  </div>
</section> -->

<!--fin reseña-->


<!--equipo-->

<!-- <section id="team" class="bg-gris">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center pb-5">
        <span class="text-celeste text-span">#familiaSodinfo</span>
        <h2 class="section-heading"><b>NUESTRO EQUIPO</b></h2>
        <hr class="celeste my-4">

      </div>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-4 col-lg-3 mb-5" *ngFor="let team of firstTeams">
          <app-profile1 [team] = "team" class="mb-5 animate__animated animate__fadeInDown"></app-profile1>
          NO VA<app-team [team] = "team" class="mb-5 animate__animated animate__fadeInDown"></app-team>
         </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-4 col-lg-3 mb-5" *ngFor="let team of secondTeams">
          <app-profile1 [team] = "team" class="mb-5 animate__animated animate__fadeInDown"></app-profile1>
         </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-4 col-lg-3 mb-5" *ngFor="let team of thirtyTeams">
          <app-profile1 [team] = "team" class="mb-5 animate__animated animate__fadeInDown"></app-profile1>
         </div>
      </div>
    </div>
  </div>
</section> -->
<!--fin equipo-->


<!--footer-->
<app-footer></app-footer>
