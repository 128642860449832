import { Pipe, PipeTransform } from '@angular/core';
import { Productos } from '../../interface/interfaces';

@Pipe({
  name: 'ramdomArray'
})
export class RamdomArrayPipe implements PipeTransform {

  transform(arreglo: Productos[] , numeroRandom: number = 1, titleExepto: string = ''){
    let arrayRamdom: Productos[] = [];
    while(arrayRamdom.length < numeroRandom){
      for(let i = 0; i < arreglo.length; i++){
        let  ramdom = arreglo[Math.floor(Math.random() * arreglo.length)];
        if(arrayRamdom.length < numeroRandom){
          if(arrayRamdom.length === 0){
            if(titleExepto === ''){
              arrayRamdom.push(ramdom);
            }else{
              let respValida = this.validaArray(arrayRamdom, titleExepto);
              if (!respValida){
                arrayRamdom.push(ramdom);
              }
            }

          }else{
            /*let existeElement = arrayRamdom.find( e => e.title === ramdom.title);*/
            let respValida = this.validaArray(arrayRamdom, ramdom.title, titleExepto);
            if (!respValida){
              arrayRamdom.push(ramdom);
            }
          }
        }
      }
    }


    return arrayRamdom;
  }

  validaArray(arreglo: Productos[], exeption: string, exeption2: string = ''){
    let existeElement = arreglo.find( e => e.title === exeption);
    if(!existeElement){
      if(exeption2 != ''){
        existeElement = arreglo.find( e => e.title === exeption2);
      }
    }
    return existeElement;
  }
}
