import { Component } from '@angular/core';

@Component({
  selector: 'app-help-facturacion-electronica',
  templateUrl: './help-facturacion-electronica.component.html',
  styleUrls: ['./help-facturacion-electronica.component.css']
})
export class HelpFacturacionElectronicaComponent {
  pdfSource = "https://sodinfo.com.ec/imagenes/ayuda.pdf";

}
