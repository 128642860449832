<app-menu></app-menu>
<app-header></app-header>


<section class="bg-gray" id="productos">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 text-center">
        <span class="text-celeste text-span">#loMejorParaLosMejores</span>
        <h2 class="section-heading">Nuestros Productos</h2>
        <hr class="azul my-4">
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-4 p-3" *ngFor="let producto of productos">
        <app-producto [producto]="producto" ></app-producto>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
