<div *ngIf="loading === false; else load">
  <app-menu></app-menu>
  <section id="loginFacturacion">
    <div class="registration-form">
      <form (ngSubmit)="loginFacturacion()" [formGroup]="loginFormFacturacion">
          <div class="form-icon">
              <span><i class="fas fa-user"></i></span>
          </div>
          <div class="form-group">
              <input type="text" class="form-control item" id="username" placeholder="Usuario" formControlName="usuario">
              <span class="form-text text-danger" *ngIf="validarCampo('usuario')">{{usuarioErrorMsg}}</span>
          </div>
          <div class="form-group">
              <input type="password" class="form-control item" id="password" placeholder="Clave" formControlName="password">
              <span class="form-text text-danger" *ngIf="validarCampo('password')">{{passwordErrorMsg}}</span>
          </div>
          <div class="form-group">
            <select class="form-control item" formControlName="perfil">
              <!--<option value='' selected='selected' >SELECCIONE SU PERFIL</option>-->
              <option value='1'> ADMINISTRADOR DEL SISTEMA </option>
              <option value='4'> OPERADOR VIP  </option>
              <option value='2'> OPERADOR DEL SISTEMA </option>
              <option value='3' > USUARIO </option>
              <option value='5' > FLOTAS </option>
              <option value='6' > STANDARD </option>
              <option value='7' > REPORTES </option>
            </select>
          </div>

          <div class="form-group text-center">
              <button type="submit" class="btn btn-block create-account" [disabled]="loginFormFacturacion.invalid">Ingresar</button>
          </div>


      </form>
      <div class="social-media">
          <h5>Siguenos en nuestras redes sociales</h5>
          <div class="social-icons">
              <a href="https://www.facebook.com/sodinfo.cialtda" target="_blank"><i class="fab fa-facebook-f white-text m-2"> </i></a>
              <a href="https://www.instagram.com/sodinfocialtda/" target="_blank"><i class="fab fa-instagram white-text m-2"> </i></a>
              <a href='index_cr.php' target="_blanck"><b><img src='https://sodinfo.com.ec/imagenes/credit.jpg' [border]='0' height="50" width="50" ></b></a>
              <a [routerLink]="'/ayuda-facturacion-electronica'" onClick='presenta_ayuda(this.form)' ><img src='https://sodinfo.com.ec/imagenes/help1.png' [border]='0' height="45" width="45" ></a>
          </div>
          <button type="button" class="btn button-sinStyle" data-bs-toggle="modal" data-bs-target="#recuperarPasswordModal" style="color:#43BAFF">¿Olvidaste tu contraseña?</button><br>
          <a href='consultas/factura-usuario.php' target="_blanck"><b><font color='#0057A4'>Descargar factura &uacute;nica </font></b></a>
      </div>
  </div>
  </section>

  <app-footer></app-footer>
</div>

  <ng-template #load>
    <app-loader [mensaje]="messageLoader"></app-loader>
  </ng-template>


  <div class="modal fade" id="recuperarPasswordModal" tabindex="-1" aria-labelledby="recuperarPasswordModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="recuperarPasswordModalLabel">¿Olvidaste tu contraseña?</h5>

          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form  autocomplete="off" [formGroup]="recupearClaveForm" (ngSubmit)="restaurarClave()" class="row g-3 needs-validation" novalidate>
            <div class="mb-3">
              <label for="recipient-name" class="col-form-label">Usuario:</label>
              <input type="text" class="form-control" id="recipient-name" formControlName="usuario">
              <div class="invalid-feedback" *ngIf="validarCampo('usuario')">
                {{usuarioPasswordErrorMsg}}
              </div>
            </div>


            <div class="mb-3">
              <button class="btn btn-primary" type="submit" [disabled]="recupearClaveForm.invalid">Restaurar Contraseña</button>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
