import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { Comentario, RespuestaComentario } from '../../interface/interfaces';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  respuesta!: RespuestaComentario;
  loading: boolean = false;
  messageLoader: string = "Cargando";
  mensaje!: RespuestaComentario;
  emailPattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  phonePattern: string = "^\(0)\(8|9|7)\([0-9]){8}";

  contactoFormulario: FormGroup = this.formBuilde.group({
    name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
    phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(this.phonePattern)]],
    email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    comentary: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(255)]]
  });

  get emailErrorMsg(): string{
    const erros = this.contactoFormulario.get('email')?.errors;
    if(erros?.required){
      return 'Email es obligatorio';
    }else if(erros?.pattern){
      return 'Email con formato incorrecto';
    }
    return '';
  }

  get nameErrorMsg(): string{
    const erros = this.contactoFormulario.get('name')?.errors;
    if(erros?.required){
      return 'El nombre es obligatorio';
    }else if(erros?.minlength){
      return 'El nombre debe tener mínimo 3 caracteres';
    }else if(erros?.maxlength){
      return 'El nombre debe tener máximo 50 caracteres';
    }
    return '';
  }

  get phoneErrorMsg(): string{
    const erros = this.contactoFormulario.get('phone')?.errors;
    if(erros?.required){
      return 'El teléfono es obligatorio';
    }else if(erros?.minLength){
      return 'El teléfono debe tener mínimo 10 caracteres';
    }else if(erros?.maxLength){
      return 'El teléfono debe tener máximo 10 caracteres';
    }else if(erros?.pattern){
      return 'Teléfono con formato incorrecto. (0929452295)';
    }
    return '';
  }

  get comentaryErrorMsg(): string{
    const erros = this.contactoFormulario.get('comentary')?.errors;
    if(erros?.required){
      return 'El comentario es obligatorio';
    }else if(erros?.minlength){
      return 'El comentario debe tener mínimo 10 caracteres';
    }else if(erros?.maxlength){
      return 'El comentario debe tener máximo 255 caracteres';
    }
    return '';
  }

  constructor(private formBuilde: FormBuilder, private dataService: DataService) { }

  ngOnInit(): void {
  }

  validarCampo(campo: string){
    return this.contactoFormulario.controls[campo]?.errors && this.contactoFormulario.controls[campo]?.touched;
  }

  async guardar(){
    if(this.contactoFormulario.invalid){
      this.contactoFormulario.markAllAsTouched();
      return;
    }
    this.loading = true;
    this.messageLoader = 'Registrando Comentario';

    console.log(this.contactoFormulario.value);
    const data:Comentario = this.contactoFormulario.value;
    await this.dataService.storeComment(data).subscribe(resp => {
      console.log(`RESULTADO: ${resp}`);
      console.log(`Status: ${resp.status}`);
      console.log(`MSM: ${resp.message}`);
      console.log(`NAME ${resp.data?.name}`)
      this.respuesta = resp;

      if(resp.status === true){
        this.contactoFormulario.reset();
        this.alerta(`Bien Hecho ${resp.data?.name}` , resp.message!);
      }else{
        //presentar los errores
        this.alerta('Upss', resp.message!, 'error');
      }
      this.loading = false;
    });


  }

  alerta(titulo: string, texto:string, icono:any = 'success'){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: icono,
      timer: 5000,
      timerProgressBar: true,
      showCloseButton: true,
      confirmButtonColor: '#43baff',
    })
  }
}


