<section id="banner">
  <div class="container">
    <div class="row justify-content-center my-auto mx-auto">
      <div class="col-md-6 text-center my-auto mx-auto">
        <h1 class="tituloBanner display-3 my-5 d-none d-md-block animate__animated animate__fadeInDown">{{title | titlecase}}</h1>
        <h1 class="tituloBanner display-4 my-5 d-block d-sm-none animate__animated animate__fadeInDown">{{title | titlecase}}</h1>
        <!--<h5 class="text-center my-5 text-white">
          {{description}}
        </h5>-->
        <!--<i class="fas fa-angle-double-down animate__animated animate__bounce animate__infinite infinite"></i>-->
      </div>
    </div>
  </div>
</section>
