import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDescription'
})
export class FormatDescriptionPipe implements PipeTransform {

  transform(texto:string, longuitd: number = 150): string{
    let text = '';
    console.log("LONG DEL TEXTO === "+ texto.length);
    if(longuitd > texto.length){
      console.log("longuitd mayor "+texto.length);
      text = texto.substring(0, texto.length);
      return text;
    }

    const long = longuitd;
    for(let i = long;i < texto.length; i++){
      console.log(`Longuitud === ${longuitd}`);
      console.log(`Caracter Seleccionado === ${texto.charAt(longuitd)}`)
      if(texto.charAt(longuitd) == ' '){
        text = texto.substring(0, longuitd);
        return text;
      }
      longuitd++;
    }

    return text;
  }

}
