<div *ngIf="loading === false; else load">
  <app-menu></app-menu>
  <section id="login">
    <main class="form-signin mt-5">
      <form class="text-center" (ngSubmit)="login()" autocomplete="off" [formGroup]="loginForm">
        <img class="mb-4" src="../assets/logo_sodinfo_cltda.png" alt="" width="100" height="100">
        <h1 class="h3 mb-3 fw-normal">Iniciar Sesión</h1>
        <span class="form-text text-danger" *ngIf="validarCampo('email')">{{emailErrorMsg}}</span>
        <div class="form-floating">
          <input type="email" formControlName="email" class="form-control" id="floatingInput" placeholder="name@example.com">
          <label for="floatingInput"><i class="fas fa-envelope"></i> Email </label>

        </div>

        <div class="form-floating">
          <input type="password" formControlName="password" class="form-control" id="floatingPassword" placeholder="Password">
          <label for="floatingPassword"><i class="fas fa-lock"></i> Password</label>
          <span class="form-text text-danger" *ngIf="validarCampo('password')">{{passwordErrorMsg}}</span>
        </div>

        <div class="checkbox mb-3 mt-3">
          <label>
            <input type="checkbox" value="remember-me"> Recuerdame
          </label>
        </div>
        <button class="w-100 btn btn-lg btn-primary" type="submit" [disabled]="loginForm.invalid">Login</button>
        <p class="mt-5 mb-3 text-muted"><a >¿Olvidaste tu contraseña?</a></p>
      </form>
    </main>

  </section>
</div>

<ng-template #load>
  <app-loader [mensaje]="messageLoader"></app-loader>
</ng-template>

