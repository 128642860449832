import { Component, Input, OnInit } from '@angular/core';
import { CardProfile } from 'src/app/interface/interfaces';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  @Input() team!: CardProfile;
  constructor() { }

  ngOnInit(): void {
  }

}
