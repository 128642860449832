import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'src/app/interface/interfaces';
import { DataService } from '../../pages/services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  menuItems: MenuItem[] = [];
  anio: number  = 2020;

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    const fecha = new Date();
    this.anio = fecha.getFullYear();

    this.menuItems = this.dataService.menuItems;
  }


  navegation(ruta: string) {
    this.dataService.navegation(ruta);
  }

}
