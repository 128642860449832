<app-menu></app-menu>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h2 class="text-white m-3">¿Comó iniciar sesión en facturación electrónica?</h2>

          <pdf-viewer [src]="pdfSource"
          [show-all]="true"
          [render-text]="false"
          [original-size]="true"
          style="display: block;height: calc(100vh - 270px)"
          ></pdf-viewer>
        </div>
      </div>
    </div>

  </section>
  <section></section>

  <app-footer></app-footer>
