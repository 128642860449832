import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { Login } from '../../interface/interfaces';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  emailPattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  loading: boolean = false;
  messageLoader: string = "Cargando";
  loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    //password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(24)]]
    password: ['', [Validators.required]]
  });

  get emailErrorMsg(): string{
    const erros = this.loginForm.get('email')?.errors;
    if(erros?.required){
      return 'Email es obligatorio';
    }else if(erros?.pattern){
      return 'Email con formato incorrecto';
    }
    return '';
  }

  get passwordErrorMsg(): string{
    const erros = this.loginForm.get('password')?.errors;
    if(erros?.required){
      return 'La contrseña es obligatoria';
    }
    return '';
  }

  constructor(private fb: FormBuilder, private dataService: DataService) { }

  ngOnInit(): void {
  }

  validarCampo(campo: string){
    return this.loginForm.controls[campo]?.errors && this.loginForm.controls[campo]?.touched;
  }

  async login(){
    this.loading = true;
    if(this.loginForm.invalid){
      this.loginForm.markAllAsTouched();
      return;
    }

    const data:Login = this.loginForm.value;
    console.log(data);

    await this.dataService.login(data).subscribe(resp => {
      if(!resp.status){
        //existe un error
        this.alerta('Upss', resp.message, 'error');

        // if(resp.errors?.email){

        // }
        this.loading = false;
        return;
      }



      setTimeout(() => { this.loading = false;   this.dataService.navegation('admin');}, 10000);

      this.loginForm.reset();
    }, (err) => {
      this.loading = false;
      this.alerta('Upss', err.error.message, 'error');
    });

  }

  alerta(titulo: string, texto:string, icono:any = 'success'){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: icono,
      timer: 5000,
      timerProgressBar: true,
      showCloseButton: true,
      confirmButtonColor: '#43baff',
    })
  }

}
