import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http'
import { ReactiveFormsModule } from '@angular/forms';

import { DpDatePickerModule } from "ng2-date-picker";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//import Swal as alerta from 'sweetalert2'

import { PagesModule } from './pages/pages.module';
import { MenuComponent } from './shared/menu/menu.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SharedModule } from './shared/shared.module';


import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    DpDatePickerModule,
    PdfViewerModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports:[
    AppRoutingModule,
    DpDatePickerModule
  ]
})
export class AppModule { }
