import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MenuItem, Comentarios, User, Person, ResponsePerson, DataUser, PaginationComentario, Comentario, RespuestaComentario, ResponseValidateToken, ResponseLogout, ResponseRoles, StorePerson, ResponseUser } from '../../interface/interfaces';
import Swal from 'sweetalert2';
import { Observable, of} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

const URL = environment.URL;

@Injectable({
  providedIn: 'root'
})
export class DataService {

  sidebarMenu: MenuItem[] = [
    {
      ruta: '/admin/dashboard',
      nombre: 'Dashboard',
      icon: 'fas fa-home'
    },
    {
      ruta: '/admin/comentarios',
      nombre: 'Comentarios',
      icon: 'fas fa-comments'
    },
    {
      ruta: '/admin/noticias',
      nombre: 'Noticias',
      icon: 'fas fa-newspaper'
    },
    {
      ruta: '/admin/usuarios',
      nombre: 'Usuarios',
      icon: 'fas fa-users'
    }
  ];

  dropDownMenu: MenuItem[] = [
    {
      ruta: '/admin/perfil',
      nombre: 'Perfil',
      icon: 'fas fa-user'
    },
    {
      ruta: '/admin/logout',
      nombre: 'Cerrar Sesión',
      icon: 'fas fa-power-off'
    }
  ];

  get localStorage(){
    const local = JSON.parse(localStorage.getItem("apiSodinfo")!);
    return local;
  }

  get token(): string{
    const local = JSON.parse(localStorage.getItem("apiSodinfo")!);
    let tokens: string = '';
    console.log(`LOCAL: ${local}`);
    if(local){
       tokens = local![0].token;
    }else{
      tokens = 'aaaaaabbbbbcccc9999';
    }


    return tokens;
  }

  get dataPeron(){
    const local = JSON.parse(localStorage.getItem("apiSodinfo")!);
    const person = local![0].person;
    console.log(`return: ${person}`);
    return <Person>person;
  }

  get dataUser(){
    const local = JSON.parse(localStorage.getItem("apiSodinfo")!);
    const user = local![0];
    return <User>user;
  }


  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${this.token}`
   });

  constructor(private http: HttpClient, private router: Router) {

  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            console.log('return if another validator has already found an error on the matchingControl');
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
          console.log('true');
            matchingControl.setErrors({ mustMatch: true });
        } else {
          console.log('null');
            matchingControl.setErrors(null);
        }
    }
  }

  getPersona(id: number){

  }

  updatePerson(data: Person, slug: string){
    console.log(data);
    const options = {headers: this.headers};
    return this.http.put<ResponsePerson>(`${URL}/personas/${slug}`, data, options);
  }

  updateUser(data: DataUser, slug: string){
    console.log(data);
    const options = {headers: this.headers};
    return this.http.put<ResponsePerson>(`${URL}/personas/${slug}`, data, options);
  }


  getComentariosIndex(){
    const options = {headers: this.headers};
    return this.http.get<PaginationComentario>(`${URL}/comentarios`, options);
  }

  getComentarios(page: number){
    const options = {headers: this.headers};
    return this.http.get<PaginationComentario>(`${URL}/comentarios?page=${page}`, options);
  }

  updateComentario(data: Comentario){
    const options = {headers: this.headers};
    return this.http.put<RespuestaComentario>(`${URL}/comentarios/${data.id}`, data, options);
  }

  deleteComentario(data: Comentario){
    const options = {headers: this.headers};
    return this.http.delete<RespuestaComentario>(`${URL}/comentarios/${data.id}`, options);
  }


  alertaSimple(titulo: string, texto:string, icono:any = 'success'){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: icono,
      timer: 5000,
      timerProgressBar: true,
      showCloseButton: true,
      confirmButtonColor: '#43baff',
    })
  }

  localDataPerson(data: Person):boolean{
    let local = this.localStorage;
    local[0].person.first_name = data.first_name;
    local[0].person.last_name = data.last_name;
    local[0].person.phone = data.phone;
    local[0].person.ruc = data.ruc;
    local[0].person.birthday = data.birthday;
    local[0].person.address = data.address;
    console.log(local);
    localStorage.setItem('apiSodinfo', JSON.stringify(local));
    return true;
  }

  logout(){
    const options = {headers: this.headers};
    return this.http.get<ResponseLogout>(`${URL}/logout`, options).pipe(
      map(resp => {
        localStorage.removeItem('apiSodinfo');
        this.router.navigateByUrl('login');
        return resp;
      }), catchError( err => {
        return err;
      })
    );

  }

  validarToken(): Observable<boolean>{
    const options = {headers: this.headers};
    console.log(options);
    return this.http.get<ResponseValidateToken>(`${URL}/validate-token`, options).pipe(
      map(resp => {
        return resp.status;
      }), catchError( err => of(false))
    );
  }

  getRoles(){
    const options = {headers: this.headers};
    return this.http.get<ResponseRoles>(`${URL}/roles`, options);
  }

  storePerson(data:any){
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.token}`
     });
    const options = {headers: headers};
    return this.http.post<ResponsePerson>(`${URL}/usuarios/registrar`, data, options);
  }

  indexUsers(){
    const options = {headers: this.headers};
    return this.http.get<ResponseUser>(`${URL}/usuarios`, options);
  }

  updateUsers(data: DataUser){
    console.log(data);
    const options = {headers: this.headers};
    return this.http.put<ResponseUser>(`${URL}/usuario/${data.id}`, data, options);
  }
}
